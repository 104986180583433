import { createSlice } from "@reduxjs/toolkit";
import { StoreState } from "types/Store";
import {
  ORDER_ACTION_TYPE,
  SIDEBAR_SELECT_CURRENCY_TYPE,
  SIDEBAR_SCREEN_TYPE,
  SELECT_CURRENCY,
  ORDER_TYPE,
} from "utils/constants";

export type OrderActionType = {
  payload: any;
};

// Initial state

const initialState: StoreState.OrderStateTypes = {
  orderAmount: "",
  orderValue: "",
  approveAssets: null,
  selectIndexPrice: null,
  selectCurrency: SELECT_CURRENCY.USDT,
  selectOrderActionType: ORDER_ACTION_TYPE.BUY,
  selectCurrencyType: SIDEBAR_SELECT_CURRENCY_TYPE.PAY,
  screenType: SIDEBAR_SCREEN_TYPE.MAIN,
  orderType: ORDER_TYPE.DEX,
  backTo: null,
};

// Reducers

const reducers = {
  setOrderAmount(state = initialState, action: OrderActionType) {
    state.orderAmount = action.payload;
  },
  setOrderValue(state = initialState, action: OrderActionType) {
    state.orderValue = action.payload;
  },
  setSelectIndexPrice(state = initialState, action: OrderActionType) {
    state.selectIndexPrice = action.payload;
  },
  setApproveAssets(state = initialState, action: OrderActionType) {
    state.approveAssets = action.payload;
  },
  setSelectCurrency(state = initialState, action: OrderActionType) {
    state.selectCurrency = action.payload;
  },
  setSelectOrderActionType(state = initialState, action: OrderActionType) {
    state.selectOrderActionType = action.payload;
  },
  setSelectCurrencyType(state = initialState, action: OrderActionType) {
    state.selectCurrencyType = action.payload;
  },
  setScreenType(state = initialState, action: OrderActionType) {
    state.screenType = action.payload;
  },
  setOrderType(state = initialState, action: OrderActionType) {
    state.orderType = action.payload;
  },
  setBackTo(state = initialState, action: OrderActionType) {
    state.backTo = action.payload;
  },
  setPendingAssetStatus: (state = initialState, action: OrderActionType) => {
    state.approveAssets?.filter((item) => item.address === action.payload.address).forEach((item) => {
      item.isPending = action.payload.status;
    });
    // const changeAsset = selectAsset?.forEach((item) => {
    //   item.isPending = true;
    // });
  },
  setApproveAssetStatus: (state = initialState, action: OrderActionType) => {
    state.approveAssets?.filter((item) => item.address === action.payload.address).forEach((item) => {
      item.isApprove = action.payload.status;
      item.balance = action.payload.balance;
    });
  },
  dropOrderData(state = initialState) {
    state.orderAmount = "";
    state.orderValue = "";
    state.orderType = ORDER_TYPE.DEX;
  },
  dropOrderState: (state = initialState) => {
    state.orderAmount = "";
    state.orderValue = "";
    state.approveAssets = null;
    state.selectCurrency = SELECT_CURRENCY.USDT;
    // state.selectOrderActionType = ORDER_ACTION_TYPE.BUY;
    state.selectCurrencyType = SIDEBAR_SELECT_CURRENCY_TYPE.PAY;
    state.screenType = SIDEBAR_SCREEN_TYPE.MAIN;
    state.orderType = ORDER_TYPE.DEX;
    state.backTo = null;
  },
  clearOrderState() {
    return initialState;
  },
};

// Slice

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers,
});

export const {
  setOrderAmount,
  setOrderValue,
  setPendingAssetStatus,
  setApproveAssetStatus,
  setSelectIndexPrice,
  setApproveAssets,
  setSelectCurrency,
  setSelectOrderActionType,
  setSelectCurrencyType,
  setScreenType,
  setOrderType,
  setBackTo,
  dropOrderData,
  dropOrderState,
  clearOrderState,
} = orderSlice.actions;

// Selectors

const selectOrderAmount = (state: StoreState.All) => state.order.orderAmount;
const selectOrderValue = (state: StoreState.All) => state.order.orderValue;
const selectApproveAssets = (state: StoreState.All) =>
  state.order.approveAssets;

const selectApproveAssetByAddress = (address: string) => (state: StoreState.All) => {
  let asset = state.order.approveAssets?.find((item) => item.address === address);
  if (asset) {
    return asset;
  } else {
    return null;
  }
}
const selectCurrency = (state: StoreState.All) => state.order.selectCurrency;
const selectOrderActionType = (state: StoreState.All) =>
  state.order.selectOrderActionType;
const selectCurrencyType = (state: StoreState.All) =>
  state.order.selectCurrencyType;
const selectScreenType = (state: StoreState.All) => state.order.screenType;
const selectBackToAction = (state: StoreState.All) => state.order.backTo;
const selectOrderType = (state: StoreState.All) => state.order.orderType;
const selectIndexPrice = (state: StoreState.All) =>
  state.order.selectIndexPrice;

export const orderSelectors = {
  selectOrderAmount,
  selectOrderValue,
  selectApproveAssets,
  selectApproveAssetByAddress,
  selectCurrency,
  selectOrderActionType,
  selectCurrencyType,
  selectScreenType,
  selectBackToAction,
  selectOrderType,
  selectIndexPrice,
};

export default orderSlice.reducer;