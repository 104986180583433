import { createSlice } from "@reduxjs/toolkit";
import { StoreState } from "types/Store";
import { CURRENCY } from "utils/constants";

export type UIActionType = {
  payload: any;
}

// Initial state

const initialState: StoreState.UiStateTypes = {
  isShowModal: false,
  isAnimate: false,
  currentBrowser: null,
  currency: CURRENCY.USD
};

// Reducers

const reducers = {
  setIsShowModal(state = initialState, action: UIActionType) {
    state.isShowModal = action.payload;
  },
  setIsAnimate(state = initialState, action: UIActionType) {
    state.isAnimate = action.payload;
  },
  setCurrentBrowser(state = initialState, action: UIActionType) {
    state.currentBrowser = action.payload;
  },
  clearUiState() {
    return initialState;
  },
};

// Slice

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers,
});

export const {
  setIsShowModal,
  setIsAnimate,
  setCurrentBrowser,
  clearUiState,
} = uiSlice.actions;

export default uiSlice.reducer;

// Selectors

const selectIsShowModal = (state: StoreState.All) => state.ui.isShowModal;
const selectIsAnimate = (state: StoreState.All) => state.ui.isAnimate;
const selectCurrency = (state: StoreState.All) => state.ui.currency;
const selectCurrentBrowser = (state: StoreState.All) => state.ui.currentBrowser;

export const uiSelectors = {
  selectIsShowModal,
  selectIsAnimate,
  selectCurrency,
  selectCurrentBrowser,
};
