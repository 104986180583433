import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { StoreState } from "types/Store";

export type UserActionType = {
  payload: any;
}

// Initial state

const initialState: StoreState.UserStateTypes = {
  isAuth: false,
};

// Reducers

const reducers = {
  setIsAuth(state = initialState, action: UserActionType) {
    state.isAuth = action.payload;
  },
  logout(state = initialState) {
    state.isAuth = false;
  },
  clearUiState() {
    return initialState;
  },
};

// Slice

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers,
});

export const {
  setIsAuth,
  logout,
  clearUiState,
} = userSlice.actions;

export default userSlice.reducer;

// Selectors

const selectIsAuth = (state: StoreState.All) => state.user.isAuth;

export const userSelectors = {
  selectIsAuth
};
