import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import { store, persistor } from "./store";
import { HelmetProvider } from "react-helmet-async";
import { ModalProvider } from "./context/modalController";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/global.css";
import "./styles/main.module.scss";
import reportWebVitals from "./reportWebVitals";
import { Loadable } from "components/Loadable/Loadable";
import { lazy } from "react";

const App = Loadable(
	lazy(() =>
		import('./App').then((module) => ({
			default: module.default,
		})),
	),
);

function getLibrary(provider: any) {
  return new ethers.providers.Web3Provider(provider);
}

ReactDOM.hydrate(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <HelmetProvider>
          <ModalProvider>
            <Web3ReactProvider getLibrary={getLibrary}>
              <App />
            </Web3ReactProvider>
          </ModalProvider>
        </HelmetProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
