import React, {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { isEqual } from "lodash-es";
import { noop } from "utils/utils";
import ModalDialog from "components/Modal";
import { v4 as uuidv4 } from "uuid";

interface InitialType {
  title: string;
  content: React.ReactNode | string | null;
  type?: string | null;
}

interface ModalContextType {
  isOpen: boolean;
  layer: InitialType;
  pushLayer: any;
  popAll: any;
}

const DEFAULT_VALUE: ModalContextType = {
  isOpen: false,
  layer: {
    title: "",
    content: null,
    type: null,
  },
  pushLayer: noop,
  popAll: noop,
};

const ModalContext = createContext<ModalContextType>(DEFAULT_VALUE);

type ModalStackType = [InitialType[], (value: InitialType) => void, () => void];

const useModalStateStack = (initialValue: InitialType): ModalStackType => {
  const [stack, setStack] = useState<InitialType[]>(() => [initialValue]);

  const push = useCallback(
    (value: InitialType) => {
      document.body.classList.add("body-overflow");
      setStack([value, ...stack]);
    },
    [setStack, stack]
  );

  const popAll = useCallback(() => {
    document.body.classList.remove("body-overflow");
    setStack([initialValue]);
  }, [setStack, initialValue]);

  return [stack, push, popAll];
};

export const ModalProvider: FC = ({ children }) => {
  const [stack, pushLayer, popAll] = useModalStateStack(DEFAULT_VALUE.layer);

  const layer = stack[0];

  const isOpen = layer.content !== null;

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        layer,
        pushLayer,
        popAll,
      }}
    >
      {children}
      <ModalDialog>
        {stack.map((el) => (
          <div
            key={uuidv4()}
            style={{
              minHeight: "100%",
              minWidth: "100%",
              width: "100%",
              display: isEqual(layer, el) ? "flex" : "none",
            }}
          >
            {el.content}
          </div>
        ))}
      </ModalDialog>
    </ModalContext.Provider>
  );
};

export const useModalController = () => useContext(ModalContext);
