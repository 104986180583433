import React from "react";

export type TIcon = {
  fillBg: string;
};

export const Logo = () => {
  return (
    <svg
      width="174"
      height="23"
      viewBox="0 0 174 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0C9.22552 0 7.00211 0.674463 5.11095 1.9381C3.21978 3.20174 1.7458 4.99779 0.875391 7.09914C0.00498275 9.20049 -0.222756 11.5128 0.220974 13.7435C0.664704 15.9743 1.75997 18.0234 3.36828 19.6317C4.97658 21.24 7.02569 22.3353 9.25647 22.779C11.4872 23.2228 13.7995 22.995 15.9009 22.1246C18.0022 21.2542 19.7983 19.7802 21.0619 17.8891C22.3255 15.9979 23 13.7745 23 11.5C23 8.45001 21.7884 5.52494 19.6317 3.36827C17.4751 1.2116 14.55 0 11.5 0V0ZM11.5 17.2512C10.3625 17.2512 9.2506 16.9139 8.30483 16.2819C7.35906 15.65 6.62192 14.7518 6.18662 13.7009C5.75133 12.65 5.63744 11.4936 5.85935 10.378C6.08126 9.26239 6.629 8.23763 7.43332 7.43331C8.23763 6.629 9.26239 6.08125 10.378 5.85934C11.4936 5.63743 12.65 5.75133 13.7009 6.18662C14.7518 6.62191 15.65 7.35905 16.2819 8.30482C16.9139 9.2506 17.2512 10.3625 17.2512 11.5C17.2515 12.2553 17.1029 13.0033 16.814 13.7012C16.5251 14.3991 16.1015 15.0333 15.5674 15.5674C15.0333 16.1015 14.3991 16.5251 13.7012 16.814C13.0033 17.1029 12.2553 17.2515 11.5 17.2512V17.2512Z"
        fill="url(#paint0_linear_603_2936)"
      />
      <path
        d="M17.6163 9.92494C17.6184 10.9246 17.4541 11.9176 17.1301 12.8633C17.065 13.0564 16.9952 13.2449 16.9137 13.4287C17.0858 12.9505 17.1922 12.4512 17.2301 11.9444C17.2301 11.9118 17.2301 11.8815 17.2301 11.849C17.2301 11.8164 17.2301 11.7164 17.2301 11.6466C17.2325 11.6133 17.2325 11.5798 17.2301 11.5465C17.2301 11.5116 17.2301 11.4791 17.2301 11.4465C17.2146 10.0857 16.717 8.77457 15.8259 7.7461C14.9347 6.71762 13.7078 6.03846 12.3631 5.82934C11.0184 5.62023 9.64316 5.89471 8.48178 6.60399C7.3204 7.31328 6.44816 8.41141 6.02011 9.70318C5.59206 10.9949 5.63593 12.3966 6.14394 13.6591C6.65194 14.9216 7.59115 15.963 8.79462 16.5983C9.99809 17.2335 11.3878 17.4215 12.7168 17.1287C14.0458 16.8359 15.2278 16.0813 16.0529 14.9991C15.2833 16.1446 14.2446 17.0842 13.0279 17.7355C11.8112 18.3869 10.4534 18.7302 9.07335 18.7355C4.35982 18.7355 0.541992 14.7804 0.541992 9.92494C0.544291 9.07034 0.668059 8.22042 0.909583 7.40066C1.15158 6.55737 1.51859 5.75512 1.9984 5.02063C2.00039 5.01206 2.00437 5.00409 2.01003 4.99736C2.32423 4.51911 2.68337 4.07194 3.08255 3.66194C3.8619 2.85754 4.79467 2.21762 5.82567 1.78004C6.85667 1.34246 7.96497 1.11612 9.08498 1.1144C13.7985 1.1144 17.6163 5.06018 17.6163 9.92494Z"
        fill="#7D7D7D"
      />
      <path
        opacity="0.5"
        d="M5.74642 11.5C5.74741 12.7003 6.1241 13.8702 6.82364 14.8457C7.52319 15.8211 8.5105 16.553 9.64711 16.9389C10.7837 17.3248 12.0126 17.3452 13.1614 16.9974C14.3102 16.6495 15.3213 15.9507 16.0529 14.9991C15.2833 16.1446 14.2446 17.0842 13.0279 17.7355C11.8112 18.3869 10.4534 18.7302 9.07335 18.7355C4.35981 18.7355 0.541992 14.7804 0.541992 9.92495C0.544291 9.07036 0.668059 8.22043 0.909583 7.40067C1.59981 5.93559 2.69006 4.69542 4.05463 3.82317C5.4192 2.95091 7.00251 2.48209 8.622 2.47077C13.3844 2.47077 17.2464 6.46774 17.2464 11.4V11.4442C17.21 9.9388 16.5845 8.50777 15.5045 7.45848C14.4245 6.40919 12.976 5.82534 11.4702 5.83235C9.96439 5.83936 8.5214 6.43666 7.45118 7.49595C6.38096 8.55525 5.76888 9.99203 5.74642 11.4977V11.5Z"
        fill="black"
      />
      <path
        d="M42.1444 7.63486C40.4127 7.63486 39.6176 8.71273 39.2642 10.303H38.7517C38.4867 8.74807 37.7799 7.63486 36.1012 7.63486C34.3696 7.63486 33.5568 8.71273 33.2034 10.303H32.4966L33.0267 8.71273V7.81156H30.1995V16.7349H33.0267V13.6073C33.0267 11.4516 33.3801 10.1087 34.9174 10.1087C35.9599 10.1087 36.2426 10.5857 36.2426 12.2821V16.7349H39.0698V13.6073C39.0698 11.4516 39.4232 10.1087 40.9605 10.1087C42.003 10.1087 42.2857 10.5857 42.2857 12.2821V16.7349H45.1129V12.0877C45.1129 9.68458 44.4238 7.63486 42.1444 7.63486Z"
        fill="black"
      />
      <path
        d="M46.1701 12.1584C46.1701 14.6852 47.7427 16.9116 51.1707 16.9116C54.263 16.9116 55.7296 14.8796 55.9063 13.5896H53.0791C52.9024 14.2611 52.1602 14.6145 51.1707 14.6145C49.5627 14.6145 48.8206 13.89 48.8206 12.8652H55.5882V11.9817C55.5882 9.27817 53.6445 7.63486 50.8703 7.63486C48.0961 7.63486 46.1701 9.33118 46.1701 12.1584ZM48.8206 11.2749C48.8206 10.6034 49.2977 9.93196 50.8703 9.93196C52.443 9.93196 52.9377 10.6034 52.9377 11.2749H48.8206Z"
        fill="black"
      />
      <path
        d="M57.1359 16.7349H59.9631V10.1793L64.5042 10.2854V7.81156L58.6555 7.6702C57.6836 7.65253 57.1359 8.18263 57.1359 9.15448V16.7349Z"
        fill="black"
      />
      <path
        d="M76.0346 7.81156C75.2395 7.81156 74.3206 7.89991 73.7905 8.58904C72.854 7.89991 71.6171 7.63486 70.2565 7.63486C67.447 7.63486 65.2206 8.76574 65.2206 11.1158C65.2206 14.0491 68.6662 14.5262 71.9352 13.3599V14.0667C70.3095 14.4555 68.4719 14.6675 66.4398 14.8796L66.7402 16.4699C69.479 16.0104 72.6773 15.4273 72.6773 16.7349C72.6773 17.7068 71.5818 17.7951 65.8037 17.7951V20.2689C72.4299 20.2689 75.5222 19.9685 75.5222 17.3357C75.5222 15.445 73.6315 14.9149 71.5994 15.0386C74.0556 14.2081 75.6282 13.1479 75.6282 11.4692C75.6282 10.9922 75.4868 10.5857 75.3808 10.4444C75.6282 10.2854 75.9463 10.2854 76.2113 10.2854H76.8121V7.81156H76.0346ZM70.4332 12.4764C68.7369 12.4764 68.0478 12.0524 68.0478 11.2925C68.0478 10.5504 68.7369 10.1087 70.4332 10.1087C72.1295 10.1087 72.801 10.5504 72.801 11.2925C72.801 12.0524 72.1295 12.4764 70.4332 12.4764Z"
        fill="black"
      />
      <path
        d="M77.3514 12.1584C77.3514 14.6852 78.9241 16.9116 82.3521 16.9116C85.4443 16.9116 86.9109 14.8796 87.0876 13.5896H84.2604C84.0837 14.2611 83.3416 14.6145 82.3521 14.6145C80.7441 14.6145 80.0019 13.89 80.0019 12.8652H86.7696V11.9817C86.7696 9.27817 84.8259 7.63486 82.0517 7.63486C79.2775 7.63486 77.3514 9.33118 77.3514 12.1584ZM80.0019 11.2749C80.0019 10.6034 80.479 9.93196 82.0517 9.93196C83.6243 9.93196 84.1191 10.6034 84.1191 11.2749H80.0019Z"
        fill="black"
      />
      <path
        d="M98.3538 16.7349V3.74746H95.5266V8.35933L96.0567 9.94963H95.3499C94.8904 8.76574 94.0423 7.63486 91.8335 7.63486C89.2537 7.63486 87.9638 9.73759 87.9638 12.2644C87.9638 14.7912 89.2537 16.9116 91.8335 16.9116C94.0423 16.9116 94.8904 15.7807 95.3499 14.5968H96.0567L95.5266 16.1871V16.7349H98.3538ZM95.5266 12.2644C95.5266 13.7663 94.6784 14.4378 93.1588 14.4378C91.6392 14.4378 90.791 13.7663 90.791 12.2644C90.791 10.7624 91.6392 10.1087 93.1588 10.1087C94.6784 10.1087 95.5266 10.7624 95.5266 12.2644Z"
        fill="black"
      />
      <path
        d="M116.249 14.2611V9.11914C116.249 8.12962 115.701 7.61719 114.712 7.63486L109.428 7.72321V7.58185C109.428 6.59233 109.799 6.00922 110.63 6.04456L114.093 6.22126V3.74746L110.983 3.57076C108.015 3.39406 106.601 5.09038 106.601 7.4935V7.77622L104.905 7.81156V10.2854L106.601 10.2677V14.2611H104.905V16.7349H118.723V14.2611H116.249ZM113.422 14.2611H109.428V10.2323L113.422 10.1793V14.2611Z"
        fill="black"
      />
      <path
        d="M125.164 10.1087C126.524 10.1087 127.001 10.7271 127.001 12.3527V16.7349H129.828V11.911C129.828 9.15448 128.45 7.63486 126.171 7.63486C124.262 7.63486 123.149 8.88943 122.796 10.4797H122.089L122.619 8.88943V7.81156H119.792V16.7349H122.619V13.4306C122.619 10.9215 123.626 10.1087 125.164 10.1087Z"
        fill="black"
      />
      <path
        d="M131.069 14.2964C131.069 15.2153 131.546 16.9116 134.32 16.9116C136.812 16.9116 137.678 15.657 138.013 14.1728H138.72L138.278 15.233V15.4097C138.278 16.2578 138.755 16.7349 139.604 16.7349H141.565V14.2611H140.275V11.752C140.275 8.81875 138.119 7.63486 135.663 7.63486C133.172 7.63486 131.246 8.96011 131.246 11.2219H133.896C133.896 10.409 134.532 9.93196 135.663 9.93196C136.882 9.93196 137.448 10.3737 137.448 11.0982L134.638 11.4869C131.988 11.858 131.069 12.7592 131.069 14.2964ZM133.896 13.7663C133.896 13.2539 134.32 13.1302 135.168 13.0065L137.448 12.6885C137.448 13.784 136.423 14.4378 134.868 14.4378C134.391 14.4378 133.896 14.2788 133.896 13.7663Z"
        fill="black"
      />
      <path
        d="M147.993 10.1087C149.354 10.1087 149.831 10.7271 149.831 12.3527V16.7349H152.658V11.911C152.658 9.15448 151.28 7.63486 149 7.63486C147.092 7.63486 145.979 8.88943 145.625 10.4797H144.918L145.449 8.88943V7.81156H142.621V16.7349H145.449V13.4306C145.449 10.9215 146.456 10.1087 147.993 10.1087Z"
        fill="black"
      />
      <path
        d="M153.709 12.2644C153.709 15.2506 155.741 16.9116 158.586 16.9116C161.024 16.9116 163.25 15.5864 163.374 13.0419H160.547C160.441 13.837 159.716 14.4378 158.586 14.4378C157.243 14.4378 156.536 13.6427 156.536 12.2644C156.536 10.9038 157.243 10.1087 158.586 10.1087C159.716 10.1087 160.441 10.7094 160.547 11.5046H163.374C163.25 8.96011 161.024 7.63486 158.586 7.63486C155.741 7.63486 153.709 9.29584 153.709 12.2644Z"
        fill="black"
      />
      <path
        d="M164.252 12.1584C164.252 14.6852 165.825 16.9116 169.253 16.9116C172.345 16.9116 173.811 14.8796 173.988 13.5896H171.161C170.984 14.2611 170.242 14.6145 169.253 14.6145C167.645 14.6145 166.902 13.89 166.902 12.8652H173.67V11.9817C173.67 9.27817 171.726 7.63486 168.952 7.63486C166.178 7.63486 164.252 9.33118 164.252 12.1584ZM166.902 11.2749C166.902 10.6034 167.38 9.93196 168.952 9.93196C170.525 9.93196 171.02 10.6034 171.02 11.2749H166.902Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_603_2936"
          x1="0"
          y1="11.5"
          x2="23"
          y2="11.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC5E4" />
          <stop offset="0.15" stopColor="#FDA34B" />
          <stop offset="0.35" stopColor="#FF7882" />
          <stop offset="0.52" stopColor="#C8699E" />
          <stop offset="0.71" stopColor="#7046AA" />
          <stop offset="0.87" stopColor="#0C1DB8" />
          <stop offset="1" stopColor="#020F75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const LogoMin = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.00693252 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0V0ZM16 24.0016C14.4174 24.0016 12.8704 23.5323 11.5545 22.6531C10.2387 21.7739 9.2131 20.5242 8.60748 19.0621C8.00185 17.6 7.84339 15.9911 8.15214 14.439C8.46088 12.8868 9.22296 11.461 10.342 10.342C11.4611 9.22295 12.8868 8.46087 14.439 8.15213C15.9911 7.84339 17.6 8.00184 19.0621 8.60747C20.5242 9.21309 21.7739 10.2387 22.6531 11.5545C23.5323 12.8704 24.0016 14.4174 24.0016 16C24.0021 17.0509 23.7954 18.0916 23.3934 19.0626C22.9914 20.0336 22.4021 20.9158 21.659 21.6589C20.9159 22.4021 20.0336 22.9914 19.0626 23.3934C18.0916 23.7954 17.0509 24.002 16 24.0016V24.0016Z"
        fill="url(#paint0_linear_1046_13143)"
      />
      <path
        d="M24.5098 13.8086C24.5126 15.1994 24.284 16.581 23.8333 17.8968C23.7426 18.1655 23.6455 18.4277 23.5322 18.6834C23.7716 18.018 23.9197 17.3233 23.9724 16.6182C23.9724 16.5729 23.9724 16.5308 23.9724 16.4855C23.9724 16.4402 23.9724 16.301 23.9724 16.2039C23.9757 16.1576 23.9757 16.1111 23.9724 16.0647C23.9724 16.0162 23.9724 15.9709 23.9724 15.9255C23.9508 14.0323 23.2585 12.2081 22.0187 10.7772C20.7789 9.34625 19.0718 8.40133 17.2009 8.11039C15.3301 7.81944 13.4166 8.20133 11.8008 9.18816C10.185 10.175 8.97143 11.7028 8.37588 13.5001C7.78033 15.2973 7.84137 17.2475 8.54816 19.004C9.25495 20.7605 10.5617 22.2094 12.2361 23.0932C13.9105 23.9771 15.844 24.2386 17.693 23.8312C19.542 23.4238 21.1866 22.374 22.3346 20.8683C21.2637 22.4621 19.8187 23.7693 18.1259 24.6755C16.4331 25.5817 14.544 26.0594 12.6239 26.0667C6.0659 26.0667 0.75415 20.564 0.75415 13.8086C0.757348 12.6196 0.929548 11.4371 1.26558 10.2966C1.60227 9.1233 2.1129 8.00712 2.78045 6.98522C2.78322 6.9733 2.78877 6.96221 2.79663 6.95285C3.23379 6.28745 3.73346 5.6653 4.28885 5.09487C5.37315 3.9757 6.67091 3.08538 8.10535 2.47657C9.53979 1.86777 11.0818 1.55286 12.6401 1.55046C19.198 1.55046 24.5098 7.04024 24.5098 13.8086Z"
        fill="#7D7D7D"
      />
      <path
        opacity="0.5"
        d="M7.9951 16C7.99648 17.67 8.52056 19.2977 9.49384 20.6548C10.4671 22.0119 11.8408 23.0303 13.4221 23.5672C15.0035 24.1041 16.7133 24.1325 18.3116 23.6485C19.91 23.1645 21.3167 22.1923 22.3346 20.8683C21.2637 22.4621 19.8187 23.7693 18.1259 24.6755C16.4331 25.5817 14.544 26.0594 12.6239 26.0668C6.0659 26.0668 0.75415 20.564 0.75415 13.8086C0.757348 12.6196 0.929548 11.4371 1.26558 10.2966C2.22589 8.25821 3.74277 6.53275 5.6413 5.31918C7.53983 4.10561 9.7427 3.45334 11.9959 3.43759C18.6218 3.43759 23.9951 8.99859 23.9951 15.8608V15.9223C23.9444 13.8279 23.0742 11.8369 21.5716 10.377C20.0689 8.91713 18.0536 8.10482 15.9586 8.11457C13.8636 8.12432 11.8559 8.95535 10.3669 10.4291C8.87793 11.9029 8.02634 13.902 7.9951 15.9968V16Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1046_13143"
          x1="0"
          y1="16"
          x2="32"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC5E4" />
          <stop offset="0.15" stopColor="#FDA34B" />
          <stop offset="0.35" stopColor="#FF7882" />
          <stop offset="0.52" stopColor="#C8699E" />
          <stop offset="0.71" stopColor="#7046AA" />
          <stop offset="0.87" stopColor="#0C1DB8" />
          <stop offset="1" stopColor="#020F75" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IndexCardDivider = () => {
  return (
    <svg
      width="520"
      height="10"
      viewBox="0 0 520 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.043 0.999964L0 0.999984V-1.55753e-05L232.295 -3.58832e-05V0.0294617L254.677 7.65765C258.128 8.83383 261.872 8.83383 265.323 7.65765L287.394 0.13554V-1.45634e-05L520 -6.10352e-05V0.999939L287.957 0.999985L265.645 8.60418C261.985 9.85165 258.015 9.85165 254.355 8.60418L232.043 0.999964Z"
        fill="#EFEFEF"
      />
    </svg>
  );
};

export const LogoTruePnl = () => {
  return (
    <svg
      width="71"
      height="15"
      viewBox="0 0 71 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.40721 10.0243V6.45296H6.92995V4.32535H4.40721V2.12174L1.97564 2.88161V4.32535H0.151974V6.45296H1.97564V10.8602C1.97564 11.696 2.43156 12.1823 3.26741 12.1519L7.23389 12V9.87234L4.40721 10.0243Z"
        fill="white"
      />
      <path
        d="M7.6948 12H10.1264V6.36178L14.0321 6.45296V4.32535L9.00176 4.20377C8.16591 4.18857 7.6948 4.64449 7.6948 5.48034V12Z"
        fill="white"
      />
      <path
        d="M18.3527 10.0243C17.1825 10.0243 16.7722 9.49241 16.7722 8.09426V4.32535H14.3406V8.4742C14.3406 10.845 15.526 12.1519 17.4865 12.1519C19.1278 12.1519 20.0852 11.0729 20.3891 9.70517H20.997L20.5411 11.0729V12H22.9727V4.32535H20.5411V7.16723C20.5411 9.32524 19.6749 10.0243 18.3527 10.0243Z"
        fill="white"
      />
      <path
        d="M23.272 8.06387C23.272 10.2371 24.6245 12.1519 27.5728 12.1519C30.2323 12.1519 31.4937 10.4042 31.6457 9.29485H29.2141C29.0621 9.87234 28.4239 10.1763 27.5728 10.1763C26.1899 10.1763 25.5516 9.5532 25.5516 8.67176H31.3721V7.9119C31.3721 5.58672 29.7004 4.17337 27.3145 4.17337C24.9285 4.17337 23.272 5.63231 23.272 8.06387ZM25.5516 7.30401C25.5516 6.72651 25.9619 6.14902 27.3145 6.14902C28.667 6.14902 29.0925 6.72651 29.0925 7.30401H25.5516Z"
        fill="white"
      />
      <path
        d="M31.9469 4.32535V14.8874H34.3784V11.5288L33.9225 10.1611H34.5304C34.9256 11.1793 35.655 12.1519 37.5547 12.1519C39.7735 12.1519 40.8829 10.3435 40.8829 8.17025C40.8829 5.99704 39.7735 4.17337 37.5547 4.17337C35.655 4.17337 34.9256 5.146 34.5304 6.16421H33.9225L34.3784 4.79646V4.32535H31.9469ZM34.3784 8.17025C34.3784 6.86329 35.1079 6.30099 36.4149 6.30099C37.7218 6.30099 38.4513 6.86329 38.4513 8.17025C38.4513 9.47722 37.7218 10.0243 36.4149 10.0243C35.1079 10.0243 34.3784 9.47722 34.3784 8.17025Z"
        fill="white"
      />
      <path
        d="M45.9618 6.30099C47.132 6.30099 47.5424 6.83289 47.5424 8.23104V12H49.9739V7.85111C49.9739 5.48034 48.7885 4.17337 46.8281 4.17337C45.1868 4.17337 44.2294 5.25238 43.9254 6.62013H43.3175L43.7734 5.25238V4.32535H41.3419V12H43.7734V9.15807C43.7734 7.00006 44.6397 6.30099 45.9618 6.30099Z"
        fill="white"
      />
      <path
        d="M50.281 12H56.9677V9.87234H54.8401V2.00016C54.8401 1.14912 54.369 0.693201 53.518 0.723595L50.5849 0.829977V2.95759L52.4086 2.86641V9.87234H50.281V12Z"
        fill="white"
      />
      <path
        d="M66.2534 2.54821H65.2325C64.8903 2.54821 64.613 2.82554 64.613 3.16765V4.30875C64.613 4.86163 63.9437 5.13754 63.5541 4.74526L63.0306 4.21819C62.7883 3.97428 62.3938 3.97428 62.1516 4.21819L61.4487 4.92588C61.2088 5.16744 61.2088 5.55735 61.4487 5.79891L65.3034 9.68004C65.5457 9.92395 65.9402 9.92395 66.1824 9.68004L70.0372 5.79891C70.2771 5.55735 70.2771 5.16744 70.0372 4.92588L69.3343 4.21819C69.092 3.97428 68.6975 3.97428 68.4553 4.21819L67.9318 4.74526C67.5422 5.13754 66.8728 4.86163 66.8728 4.30875V3.16765C66.8728 2.82554 66.5955 2.54821 66.2534 2.54821Z"
        fill="white"
      />
      <path
        d="M66.2534 12.3009H65.2325C64.8903 12.3009 64.613 12.0236 64.613 11.6815V10.5404C64.613 9.98749 63.9437 9.71158 63.5541 10.1039L63.0306 10.6309C62.7883 10.8748 62.3938 10.8748 62.1516 10.6309L61.4487 9.92324C61.2088 9.68168 61.2088 9.29177 61.4487 9.05021L65.3034 5.16908C65.5457 4.92517 65.9402 4.92517 66.1824 5.16908L70.0372 9.05021C70.2771 9.29177 70.2771 9.68168 70.0372 9.92324L69.3343 10.6309C69.092 10.8748 68.6975 10.8748 68.4553 10.6309L67.9318 10.1039C67.5422 9.71158 66.8728 9.98749 66.8728 10.5404V11.6815C66.8728 12.0236 66.5955 12.3009 66.2534 12.3009Z"
        fill="#3BDA67"
      />
    </svg>
  );
};

export const AngleDown: React.FC<TIcon> = ({ fillBg }) => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76526 0.445051C7.54601 0.225809 7.19055 0.225809 6.97131 0.445051L3.99986 3.4165L1.02841 0.44505C0.809172 0.225808 0.453711 0.225808 0.23447 0.44505C0.0152275 0.664292 0.0152275 1.01975 0.23447 1.23899L3.20592 4.21044C3.6444 4.64893 4.35532 4.64893 4.79381 4.21044L7.76526 1.23899C7.9845 1.01975 7.9845 0.664292 7.76526 0.445051Z"
        fill={fillBg}
      />
    </svg>
  );
};

export const AngleLeft: React.FC<TIcon> = ({ fillBg }) => {
  return (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89504 7.89489C5.11428 7.67565 5.11428 7.32019 4.89504 7.10095L1.92359 4.1295L4.89504 1.15805C5.11428 0.938811 5.11428 0.58335 4.89504 0.364108C4.6758 0.144866 4.32033 0.144866 4.10109 0.364108L1.12964 3.33556C0.691161 3.77404 0.691159 4.48496 1.12964 4.92345L4.10109 7.89489C4.32033 8.11414 4.6758 8.11414 4.89504 7.89489Z"
        fill={fillBg}
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="circleinfo"
        d="M16.3054 8.99999C16.3054 13.0345 13.0347 16.3052 9.00017 16.3052C4.96563 16.3052 1.69498 13.0345 1.69498 8.99999C1.69498 4.96544 4.96563 1.6948 9.00017 1.6948C13.0347 1.6948 16.3054 4.96544 16.3054 8.99999Z"
        fill="#FAFAFA"
        // fillOpacity="0.1"
        stroke="#C9C9C9"
        strokeWidth="0.818182"
      />
      <path
        id="markinfo"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03794 4.81849C8.03794 5.34803 8.46751 5.77979 8.99376 5.77979C9.53539 5.77979 9.96605 5.34803 9.96605 4.81849C9.96605 4.28894 9.53539 3.85718 9.00475 3.85718C8.47191 3.85718 8.03794 4.28894 8.03794 4.81849ZM9.95582 8.30436C9.95582 7.77482 9.52405 7.34305 8.99451 7.34305C8.46497 7.34305 8.0332 7.77482 8.0332 8.30436V13.1603C8.0332 13.6899 8.46497 14.1216 8.99451 14.1216C9.52405 14.1216 9.95582 13.6899 9.95582 13.1603V8.30436Z"
        fill="#C9C9C9"
      />
    </svg>
  );
};

export const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      //  style="margin: auto; background: rgb(241, 242, 243); display: block; shape-rendering: auto;"
      width="54px"
      height="54px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.9166666666666666s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.8333333333333334s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.75s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.6666666666666666s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5833333333333334s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.4166666666666667s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.3333333333333333s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.25s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.16666666666666666s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.08333333333333333s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#F68419">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8087 0.673494C12.4205 0.285267 11.7911 0.285267 11.4028 0.673494L4.85873 7.21761L2.59697 4.95585C2.20874 4.56762 1.5793 4.56762 1.19107 4.95585C0.802846 5.34407 0.802846 5.97351 1.19107 6.36174L4.15578 9.32645C4.54401 9.71467 5.17345 9.71467 5.56167 9.32645L12.8087 2.07939C13.197 1.69116 13.197 1.06172 12.8087 0.673494Z"
        fill="white"
      />
    </svg>
  );
};

export const ProcessCompleteIcon = () => {
  return (
    <svg
      width="83"
      height="83"
      viewBox="0 0 83 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6587 41.083L35.946 51.366L56.512 30.8"
        stroke="#8DDF8B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 41.0833C1 71.1437 11.023 81.1667 41.0833 81.1667C71.1437 81.1667 81.1667 71.1437 81.1667 41.0833C81.1667 11.023 71.1437 1 41.0833 1C11.023 1 1 11.023 1 41.0833Z"
        stroke="#8DDF8B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProcessErrorIcon = () => {
  return (
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.3525 30.5989L30.6304 51.321"
        stroke="#FF0D1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3788 51.3481L30.6221 30.5913"
        stroke="#FF0D1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 41C1 70.9978 11.0022 81 41 81C70.9978 81 81 70.9978 81 41C81 11.0022 70.9978 1 41 1C11.0022 1 1 11.0022 1 41Z"
        stroke="#FF0D1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SpinnerPending = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="30"
        stroke="#c9c9c9"
        strokeWidth="7"
        fill="none"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="30"
        stroke="#0d0d0d"
        strokeWidth="7"
        strokeLinecap="square"
        fill="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;180 50 50;720 50 50"
          keyTimes="0;0.5;1"
        ></animateTransform>
        <animate
          attributeName="stroke-dasharray"
          repeatCount="indefinite"
          dur="1s"
          values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
          keyTimes="0;0.5;1"
        ></animate>
      </circle>
    </svg>
  );
};
