import React, { FC, useRef } from "react";
import cn from "classnames";
import style from "./styles.module.scss";
import { useModalController } from "context/modalController";

const ModalDialog: FC = ({ children }) => {
  const { isOpen, layer, popAll } = useModalController();

  const backRef = useRef<HTMLDivElement>(null);

  const close = () => {
    popAll();
  };

  const closeModal = (e: React.MouseEvent) => {
    if (backRef.current === e.target) {
      close();
    }
  };
  
  return (
    <div
      ref={backRef}
      className={cn(style.modalDialog, isOpen && style["modalDialog--open"])}
      onClick={closeModal}
    >
      <div
        className={cn(
          style.modalDialog__content,
          layer?.type === "youtube" && style["modalDialog__content--yt"],
          isOpen && style["modalDialog__content--open"]
        )}
      >
        <span className={style.modalDialog__title}>{layer?.title}</span>
        {children}
      </div>
    </div>
  );
};

export default ModalDialog;
