export const CURRENCY = Object.freeze({
  USD: "USD",
  EUR: "EUR",
});

export const COIN_NAMES = Object.freeze({
  USDC: "USDC",
  USDT: "USDT",
  BNB: "BNB",
  "1INCH": "1INCH",
  ETH: "ETH",
  BUSD: "BUSD",
  BTC: "BTC",
});

export const NETWORKS_TYPE = Object.freeze({
  BSC: "BSC",
  ETHER: "ETHER",
  SOLANA: "SOLANA",
  POLYGON: "POLYGON",
  rinkby: "rinkby",
});

export const NETWORKS_CAPTION: Record<string, string> = Object.freeze({
  [NETWORKS_TYPE.BSC]: "Binance Smart Chain",
  [NETWORKS_TYPE.ETHER]: "Ethereum Network",
  [NETWORKS_TYPE.SOLANA]: "Solana Network",
  [NETWORKS_TYPE.POLYGON]: "Polygon Network",
  [NETWORKS_TYPE.rinkby]: "Rinkeby Testnet Network"
});

export const NETWORKS_LINK = Object.freeze({
  // [NETWORKS_TYPE.BSC]: "https://bscscan.com/token/",
  [NETWORKS_TYPE.BSC]: "https://testnet.bscscan.com/token/",
  [NETWORKS_TYPE.ETHER]: "https://etherscan.io/token/",
  [NETWORKS_TYPE.SOLANA]: "https://explorer.solana.com/address/",
  [NETWORKS_TYPE.POLYGON]: "https://polygonscan.com/token/",
  [NETWORKS_TYPE.rinkby]: "https://rinkeby.etherscan.io/address/",
});

export const NETWORKS_TX_LINK = Object.freeze({
  // [NETWORKS_TYPE.BSC]: "https://bscscan.com/tx/",
  [NETWORKS_TYPE.BSC]: "https://testnet.bscscan.com/tx/",
  [NETWORKS_TYPE.ETHER]: "https://etherscan.io/tx/",
  [NETWORKS_TYPE.SOLANA]: "https://explorer.solana.com/address/",
  [NETWORKS_TYPE.POLYGON]: "https://polygonscan.com/tx/",
  [NETWORKS_TYPE.rinkby]: "https://rinkeby.etherscan.io/tx/",
});

export const NERWORKS_LINK_CAPTION = Object.freeze({
  [NETWORKS_TYPE.BSC]: "BSC Scan",
  [NETWORKS_TYPE.ETHER]: "Ether Scan",
  [NETWORKS_TYPE.SOLANA]: "Solana Explorer",
  [NETWORKS_TYPE.POLYGON]: "Polygon Scan",
  [NETWORKS_TYPE.rinkby]: "Ether Scan",
});

export const NETWORKS_TOKEN_SYMBOL: Record<string, string> = Object.freeze({
  [NETWORKS_TYPE.BSC]: "BNB",
  [NETWORKS_TYPE.ETHER]: "ETH",
  [NETWORKS_TYPE.POLYGON]: "MATIC",
  [NETWORKS_TYPE.SOLANA]: "SOL",
  [NETWORKS_TYPE.rinkby]: "ETH",
});

export const NETWORKS_TOKEN_ADDRESS = Object.freeze({
  [NETWORKS_TYPE.ETHER]: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
  [NETWORKS_TYPE.rinkby]: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
})

export const CHART_CONTROLS_TYPE: Record<string, string> = Object.freeze({
  DAY: "DAY",
  MONTH: "MONTH",
  YEAR: "YEAR",
});

export const CHART_CONTROLS_CAPTION: Record<string, string> = Object.freeze({
  [CHART_CONTROLS_TYPE.DAY]: "24H",
  [CHART_CONTROLS_TYPE.MONTH]: "1M",
  [CHART_CONTROLS_TYPE.YEAR]: "1Y",
});

export const ORDER_ACTION_TYPE = Object.freeze({
  BUY: "BUY",
  SELL: "SELL",
});

export const UNDASSETS_LIST_CAPTION = Object.freeze({
  [ORDER_ACTION_TYPE.BUY]: {
    a: "Req. Amount",
    b: "My balance",
  },
  [ORDER_ACTION_TYPE.SELL]: {
    a: "Amount per index",
    b: "Receive",
  },
});

export const SIDEBAR_SCREEN_TYPE = Object.freeze({
  MAIN: "MAIN",
  PAY: "PAY",
  RECEIVE: "RECEIVE",
  CONFIRMBUY: "CONFIRMBUY",
  CONFIRMSELL: "CONFIRMSELL",
  UASSETSBUY: "UASSETSBUY",
  UASSETSSELL: "UASSETSSELL",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
});

export const SIDEBAR_SELECT_CURRENCY_TYPE = Object.freeze({
  PAY: "PAY",
  RECEIVE: "RECEIVE",
});

export const SIDEBAR_SELECT_CURRENCY_CAPTION = Object.freeze({
  [SIDEBAR_SELECT_CURRENCY_TYPE.PAY]: "Pay",
  [SIDEBAR_SELECT_CURRENCY_TYPE.RECEIVE]: "Receive",
});

export const SELECT_CURRENCY = Object.freeze({
  BNB: "BNB",
  USDT: "USDT",
  BUSD: "BUSD",
});

export const ORDER_TYPE = Object.freeze({
  DEX: "DEX",
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
});

export const ORDER_TYPE_CAPTION: Record<string, any> = Object.freeze({
  [ORDER_ACTION_TYPE.BUY]: {
    [ORDER_TYPE.DEX]: "DEX Swap",
    [ORDER_TYPE.SINGLE]: "Issue via single asset",
    [ORDER_TYPE.MULTIPLE]: "Issue via multiple assets",
  },
  [ORDER_ACTION_TYPE.SELL]: {
    [ORDER_TYPE.DEX]: "DEX Swap",
    [ORDER_TYPE.SINGLE]: "Redeem via single asset",
    [ORDER_TYPE.MULTIPLE]: "Redeem via multiple assets",
  },
});

export const ORDER_TYPE_HINT_TEXT: Record<string, any> = Object.freeze({
  [ORDER_ACTION_TYPE.BUY]: {
    [ORDER_TYPE.DEX]: "Buying the index on a DEX (from secondary market) upon availability.",
    [ORDER_TYPE.SINGLE]: "Your payment token will be exchanged for each of the underlying assets and then index will be minted.",
    [ORDER_TYPE.MULTIPLE]: "Provide the comprising underlying assets to mint the index.",
  },
  [ORDER_ACTION_TYPE.SELL]: {
    [ORDER_TYPE.DEX]: "Selling the index on a DEX (from secondary market) upon availability.",
    [ORDER_TYPE.SINGLE]: "Exchanging underlying assets for selected withdrawal currency.",
    [ORDER_TYPE.MULTIPLE]: "Burning the index to redeem the contained underlying assets.",
  },
});

export const APPROVE_PROCESS_TYPE = ["request", "success", "pending", "failed"];

export const ASSET_PROGRESS_BG_TYPE = Object.freeze({
  WETH: "WETH",
  ETH: "ETH",
  BNB: "BNB",
  NEAR: "NEAR",
  CAKE: "CAKE",
  USDT: "USDT",
  AVAX: "AVAX",
  CHR: "CHR",
  JOE: "JOE",
  XAVA: "XAVA",
  MATIC: "MATIC",
  QI: "QI",
  PTP: "PTP",
  PNG: "PNG",
});

export const ASSET_PROGRESS_BG: Record<string, string> = Object.freeze({
  [ASSET_PROGRESS_BG_TYPE.WETH]: "#00D395",
  [ASSET_PROGRESS_BG_TYPE.ETH]: "#0D0D0D",
  [ASSET_PROGRESS_BG_TYPE.BNB]: "#F3BA2F",
  [ASSET_PROGRESS_BG_TYPE.NEAR]: "#000000",
  [ASSET_PROGRESS_BG_TYPE.CAKE]: "#FF007A",
  [ASSET_PROGRESS_BG_TYPE.USDT]: "#1C60FF",
  [ASSET_PROGRESS_BG_TYPE.AVAX]: "#E84142",
  [ASSET_PROGRESS_BG_TYPE.CHR]: "#FFB0C2",
  [ASSET_PROGRESS_BG_TYPE.JOE]: "#F2716A",
  [ASSET_PROGRESS_BG_TYPE.XAVA]: "#000000",
  [ASSET_PROGRESS_BG_TYPE.QI]: "#00B2EC",
  [ASSET_PROGRESS_BG_TYPE.PTP]: "#0082C8",
  [ASSET_PROGRESS_BG_TYPE.MATIC]: "#8247E5",
  [ASSET_PROGRESS_BG_TYPE.PNG]: "#FFC800"
});

export const CONTRACTS = Object.freeze({
  CONTROLLER: "0x65297b238D5Ca5995eF6fC0aAf844bc664466878§",
  SET_TOKEN_CREATOR: "0x397D85463a06982985053Ee560BD850aB4801627",
  BASIC_ISSUE_MODULE: "0xfF9Eec7711bE1Bb81e77E0339F0BDe44de321783",
  PNL_SET: "0xcfc6Aca3B1bF8A07f05CC25951B07Bc688285736",
  NAV_ISSUE_MODULE: "0xa45B3ad2c05Bb1F1A2148A9f35BA4ce9398ABa85",
  DEX_ISSUE_MODULE: "0x131Bd7f4794B10Ec8113e04A81a7D97Cf6FBBB94",
});

export const TOKENS = Object.freeze({
  USDT: "0x9c960a5df8df917d6aF153b717312b225A1d8f55",
  PNL: "0x4573353c9a6e0966B387E538585556DDFE4AD874",
  DAO: "0xEFE57e02FFaF32f1C47F614d6f89d0e7a903cd43",
});

export const NETWORK_OBJ = Object.freeze({
  chainId: `0x${Number(97).toString(16)}`,
  chainName: "Binance Smart Chain Testnet",
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
  },
  rpcUrls: [
    "https://data-seed-prebsc-1-s1.binance.org:8545",
    "https://data-seed-prebsc-2-s1.binance.org:8545",
    "https://data-seed-prebsc-1-s2.binance.org:8545",
    "https://data-seed-prebsc-2-s2.binance.org:8545",
    "https://data-seed-prebsc-1-s3.binance.org:8545",
    "https://data-seed-prebsc-2-s3.binance.org:8545"
  ],
  blockExplorerUrls: ["https://testnet.binance.org/"]
});

export const BNB_PRICE = 380;
