import {
  configureStore,
  combineReducers,
  AnyAction,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { StoreState } from "types/Store";
import uiReducer from "./uiSlice";
import orderReducer from "./orderSlice";
import userReducer from "./userSlice";
import metamaskReducer from "./metamaskSlice";
import indicesReducer from "./indicesSlice";

export const rootReducer = combineReducers<StoreState.All, AnyAction>({
  user: userReducer,
  ui: uiReducer,
  order: orderReducer,
  metamask: metamaskReducer,
  indices: indicesReducer,
});

const rootPersistConfig = {
  key: "merged-root",
  storage,
  blacklist: ["errors", "ui", "metamask", "order"],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer: any = persistReducer<RootState>(
  rootPersistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
