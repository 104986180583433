import { createSlice } from "@reduxjs/toolkit";
import { StoreState } from "types/Store";
import { CURRENCY } from "utils/constants";

export type MetamaskActionType = {
  payload: any;
}

// Initial state

const initialState: StoreState.MetamaskStateTypes = {
  txHash: null,
  isSuccess: false,
  gasPrice: null,
  shouldDrop: true,
  error: null,
};

// Reducers

const reducers = {
  setTxHash(state = initialState, action: MetamaskActionType) {
    state.txHash = action.payload;
  },
  setIsSuccess(state = initialState, action: MetamaskActionType) {
    state.isSuccess = action.payload;
  },
  setGasPrice(state = initialState, action: MetamaskActionType) {
    state.gasPrice = action.payload;
  },
  setShouldDrop(state = initialState, action: MetamaskActionType) {
    state.shouldDrop = action.payload;
  },
  setMetaMaskError(state = initialState, action: MetamaskActionType) {
    const { code, message } = action.payload
    state.error = { code, message }
  },
  clearMetamaskState(state = initialState) {
    if (state.shouldDrop) {
      return initialState;
    }
  },
};

// Slice

const metamaskSlice = createSlice({
  name: "metamask",
  initialState,
  reducers,
});

export const {
  setTxHash,
  setIsSuccess,
  setGasPrice,
  setShouldDrop,
  setMetaMaskError,
  clearMetamaskState,
} = metamaskSlice.actions;

export default metamaskSlice.reducer;

// Selectors

const selectTxHash = (state: StoreState.All) => state.metamask.txHash;
const selectIsSuccess = (state: StoreState.All) => state.metamask.isSuccess;
const selectGasPrice = (state: StoreState.All) => state.metamask.gasPrice;
const selectShouldDrop = (state: StoreState.All) => state.metamask.shouldDrop;
const selectMetaMaskError = (state: StoreState.All) => state.metamask.error;

export const metamaskSelectors = {
  selectTxHash,
  selectIsSuccess,
  selectGasPrice,
  selectShouldDrop,
  selectMetaMaskError
};
