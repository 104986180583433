import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { ORDER_ACTION_TYPE, ORDER_TYPE } from "./constants";

export const noop = () => {};

export const getCurrencySymbol = (cur: string | undefined) => {
  if (cur === undefined) return;
  const currency_symbols: any = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
  };

  const currency_name = cur;

  if (currency_symbols[currency_name] !== undefined) {
    return currency_symbols[currency_name];
  }
};

export const formatDate = (date: string) => {
  let newDate = new Date(date).getTime();
  return format(newDate, "dd MMM yyyy", { locale: enUS });
}

export const formatDateForChart = (date: number) => {
  let newDate = new Date(date).getTime();
  return format(newDate, "dd MMM", { locale: enUS });
}

export const formatTimeForChart = (date: number) => {
  let newDate = new Date(date).getTime();
  return format(newDate, "HH:mm", { locale: enUS });
}

export const formatTime = (value: number) =>
  Math.floor(value / 10) === 0 ? `0${value}` : `${value}`;

export const printBtnOrderText = (ordActionType: string, ordType: string) => {
  switch (ordActionType && ordType) {
    case ORDER_ACTION_TYPE.BUY && ORDER_TYPE.DEX:
      return "Review Order";
    case ORDER_ACTION_TYPE.BUY && ORDER_TYPE.SINGLE:
      return "Review Order";
    case ORDER_ACTION_TYPE.BUY && ORDER_TYPE.MULTIPLE:
      return "Next";
    case ORDER_ACTION_TYPE.SELL && ORDER_TYPE.DEX:
      return "Review Order";
    case ORDER_ACTION_TYPE.SELL && ORDER_TYPE.SINGLE:
      return "Review Order";
    case ORDER_ACTION_TYPE.SELL && ORDER_TYPE.MULTIPLE:
      return "Next";
    default:
      return "Review Order";
  }
};

export const roundToEightDecimals = (value: number, decimals: number) => {
  if (isNaN(value)) return '0.00';
  let roundedValue = value.toFixed(decimals);
  roundedValue = roundedValue.replace(/\.?0+$/, '');
  let finalValue = parseFloat(roundedValue).toFixed(decimals);
  finalValue = finalValue.replace(/\.?0+$/, '');
  return finalValue;
}

export const displayAssetRangeGrowth = (value?: number) => {
  if (typeof value === 'undefined' || isNaN(value)) return '0%';
  return `${value}%`;
}