import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { StoreState } from "types/Store";
import { apiClient } from "api/client";
import { TIndexAssets } from "types/Index";

export type IndicesActionType = {
  payload: any;
};

// Initial state

const initialState: StoreState.IndicesStateTypes = {
  indices: [],
  graphData: null,
  indexGraph: null,
};

// Reducers

const reducers = {
  setIndices(state = initialState, action: IndicesActionType) {
    state.indices = action.payload;
  },
  setGraphData(state = initialState, action: IndicesActionType) {
    state.graphData = action.payload;
  },
  setIndexGraph(state = initialState, action: IndicesActionType) {
    state.indexGraph = action.payload;
  },
  addAssetsToIndex: (state = initialState, action: IndicesActionType) => {
    if (state.indices !== null) {
      state.indices
        ?.filter((item) => item.id === action.payload.id)
        .forEach((item) => {
          item.index_assets = action.payload.index_assets;
        });
    }
  },
  setAssetsData: (state = initialState, action: IndicesActionType) => {
    const selectIndex = state.indices.find((item) => item.id === action.payload.id);
    selectIndex.index_assets.forEach((item: TIndexAssets) => {
      let currentAset = action.payload.assets.find((asset: any) => asset.id === item.asset_id);
      item.asset.price = currentAset.price;
      item.asset.range_growth = currentAset.range_growth;
    });
  },
  clearIndicesState() {
    return initialState;
  },
};

// Slice

const indicesSlice = createSlice({
  name: "indices",
  initialState,
  reducers,
});

export const {
  setIndices,
  setGraphData,
  setAssetsData,
  addAssetsToIndex,
  setIndexGraph,
  clearIndicesState,
} = indicesSlice.actions;

// thunk
export const fetchIndexes = () => async (dispatch: Dispatch) => {
  try {
    const res = await apiClient.getIndexes();
    if (res.error) {
      throw res;
    }
    dispatch(setIndices(res));
  } catch (error) {
    console.log(error);
  }
};

export const fetchAssetsByIndexId =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      const res = await apiClient.getIndexAssetsById(id);
      if (res.error) {
        throw res;
      }
      dispatch(addAssetsToIndex(res[0]));
    } catch (error) {
      console.log(error);
    }
  };

export const fetchIndexGraphData =
  (id: number, fromDate: string, toDate: string) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await apiClient.getGraphByIndexId(id, fromDate, toDate);
      if (res.error) {
        throw res;
      }
      const obj = {
        id: id,
        assets: res.assets
      }
      dispatch(setAssetsData(obj))
      dispatch(setGraphData(res));
    } catch (error) {
      console.log(error);
    }
  };

// Selectors

const selectIndexById = (id: number) => (state: StoreState.All) =>
  state.indices.indices?.find((item) => item.id === id);
const selectIndexGraph = (state: StoreState.All) => state.indices.indexGraph;
const selectGraphData = (state: StoreState.All) => state.indices.graphData;
const selectAllIndeces = (state: StoreState.All) => state.indices.indices;

export const indecsSelectors = {
  selectIndexById,
  selectIndexGraph,
  selectGraphData,
  selectAllIndeces,
};

export default indicesSlice.reducer;