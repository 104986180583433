import { Spinner } from 'components/svgIcons';
import { type ElementType, Suspense } from 'react';
import style from "./styles.module.scss";

export const Loadable = (Component: ElementType) => {
	return (props: any) => {
		return (
			<Suspense
				fallback={
					<div className={style.pageLoader}>
						<Spinner />
					</div>
				}
			>
				<Component {...props} />
			</Suspense>
		);
	};
};
