export const SUBSCRIBE_URL = "https://launchpad.truepnl.com/api/v1";
export const BASE_URL = "https://merged.gg/api/v1";
export const PANCAKE_URL = "https://api.pancakeswap.info/api/v2";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const subscribeEmail = async (body: { email: string; type: string }) => {
  const response = await fetch(`${SUBSCRIBE_URL}/mail/subscribe`, {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify(body),
  });
  if (response.status === 204) return "";
  const result = await response.json();
  return result;
};

const getIndexes = async () => {
  const response = await fetch(`${BASE_URL}/indices`, {
    headers: {
      ...headers,
    },
    method: "GET",
  });
  const result = await response.json();
  return result;
};

const getIndexAssetsById = async (id: number) => {
  const response = await fetch(`${BASE_URL}/indices/${id}/assets`, {
    headers: {
      ...headers,
    },
    method: "GET",
  });
  const result = await response.json();
  return result;
};

const getGraphByIndexId = async (id: number, from: string, to: string) => {
  const response = await fetch(`${BASE_URL}/indices/${id}/graph?from=${from}&to=${to}`, {
    headers: {
      ...headers,
    },
    method: "GET",
  });
  const result = await response.json();
  return result;
}

const getTokenPrice = async (address: string) => {
  const response = await fetch(`${PANCAKE_URL}/tokens/${address}`, {
    headers: {
      ...headers,
    },
    method: "GET",
  });
  const result = await response.json();
  return result;
}

export const apiClient = {
  subscribeEmail,
  getIndexes,
  getIndexAssetsById,
  getGraphByIndexId,
  getTokenPrice
};
